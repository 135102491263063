
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function vehicleLabelContainerRT () {
    return _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__page-title' }, _createElement('h1', { 'className': 'collection__title h1 heading' }, this.template === 'locked' ? _createElement('div', {
        'className': 'cm_vehicle-widget_label-container cmTemplate_locked',
        'key': '105'
    }, _createElement('span', { 'className': 'cm_vehicle-widget_title' }, this.fitmentSearchTitle, ' '), _createElement('span', { 'className': 'cm_vehicle-widget_subtitle' }, 'for the ', this.vehicleString)) : null, this.template !== 'locked' ? [_createElement('span', {
            'className': 'cm_vehicle-widget_title',
            'key': '3931'
        }, this.fitmentSearchTitle)] : null));
}
        export const componentNames = []